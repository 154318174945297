<template>
  <v-navigation-drawer
    v-model="drawer"
    right
    fixed
    temporary
    app
  >
    <v-list
      nav
    >
      <v-list-item-group
        active-class="accent"
      >
        <v-list-item
          v-for="(item, indx) in menuItems"
          :key="'menu-' + indx"
          @click="moveTo(item.route)"
        >
          <v-list-item-icon v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'AppBarDrawer',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      menuItems: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      drawer: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit('input', newVal)
        },
      },
    },
    methods: {
      moveTo (id) {
        const element = document.getElementById(id)
        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
        this.drawer = false
      },
    },
  }
</script>
